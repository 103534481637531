import React, { useState } from "react";
import "./login.css";
import {
  Form,
  Input,
  Button,
  notification,
  Typography,
  Modal,
  Result,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import logo from "../../Assets/OnlineTV.svg";

import plutao from "../../Services/api";
import { Link, useNavigate } from "react-router-dom";
import yasoba from "../../Services/yasoba";

function Login() {
  // Mudando title do HTML
  document.title = "Login | Online TV";


  const [statusEmail, setStatusEmail] = useState();
  const [statusSenha, setStatusSenha] = useState();
  const [loading, setLoading] = useState(false);
  const [msgEmail, setMsgEmail] = useState();
  const [msgSenha, setMsgSenha] = useState();



  const [forgot, setForgot] = useState(false);
  const [emailRec, setEmailRec] = useState("");
  const [resultSenha, setResultSenha] = useState(false);
  const [resultSenhaForm, setResultSenhaForm] = useState(false);
  const [msgEmailRec, setMsgEmailRec] = useState("");



  const navigate = useNavigate();

  function Auth({ email, senha }) {
    // Setando o estado de carregando do input e botão.
    setStatusEmail("validating");
    setStatusSenha("validating");
    setLoading(true);

    // Configurando timer para iniciar requisição.

    plutao
      .post("/api/auth", {
        emailrq: email,
        senharq: senha,
      })
      .then((response) => {
        localStorage.setItem("cronos_token-user", response.data.Token);
        //Verificando se autenticou pelo Status.
        if (response.data.Status === 200) {
          //Setando o estado de sucesso do input e botão.
          setLoading(false);
          setStatusEmail("success");
          setStatusSenha("success");
          // Adicionando o token de resposta ao localStorage.
          plutao.defaults.headers.Authorization = response.data.Token;
          yasoba.defaults.headers.Authorization = response.data.Token;

          localStorage.setItem("cronos_token-user", response.data.Token);
          localStorage.setItem("cronos_id_user", response.data.id_user);
          navigate("/");
        } else {
          // Verificando resposta de erro caso erro no e-mail.
          if (response.data.Resposta === "E-mail Incorreto") {
            // Setando comportamentos para alertar o erro no e-mail.
            setLoading(false);
            setStatusEmail("error");
            setMsgEmail(response.data.Resposta);
          } else {
            // Setando comportamentos para dos botões e input.
            setLoading(false);
            setStatusEmail("");
            setMsgEmail("");
          }
          // Verificando resposta de erro caso erro na senha.
          if (response.data.Resposta === "Senha Inválida") {
            // Setando comportamentos para alertar o erro senha.
            setLoading(false);
            setStatusSenha("error");
            setMsgSenha(response.data.Resposta);
          } else {
            // Setando comportamentos para dos botões e input.
            setLoading(false);
            setStatusSenha("");
            setMsgSenha("");
          }
        }
      })
      .catch((erro) => {
        // Setando comportamentos para dos botões e input.
        setLoading(false);
        setStatusEmail("error");
        setStatusSenha("error");
        // Adicionando notificação de erro em caso de falha na resposta vindo da API.
        notification.error({
          duration: 10,
          message: "Erro de comunicação",
          description:
            "Falha ao se comunicar com o servidor. Caso o erro persista, entre em contato com o administrador do sistema.",
        });
      });
  }

  function forgotPass() {
    setForgot(true);
    setResultSenhaForm(true);
  }

  async function esqueceuSenha({ emailForgot }) {
    const route = window.location.origin
    console.log(route)
    setLoading(true);
    setEmailRec(emailForgot);
    await plutao
      .post("/email/esqueceu-senha", { email: emailForgot,origin: route })
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        setResultSenhaForm(false);
        setResultSenha(true);
      })
      .catch((err) => {
        setLoading(false)
        setMsgEmailRec("Email não existe")
        console.log("erro; " + err);
      });
  }

  return (
    <div className="login-page">
      <Modal
        
        visible={forgot}
        footer={false}
        centered
        onCancel={() => {
          if (!loading) {
            setForgot(false);
            setResultSenhaForm(false);
            setResultSenha(false);
            setMsgEmailRec('')
          }
        }}
      >
        {resultSenhaForm ? (
          <div className="modal-content">
            <div className="title">
              <h3 style={{ fontWeight: "bold" }}>Problemas para entrar?</h3>
              <p>
                Insira o seu email e enviaremos um link para você voltar a
                acessar a sua conta.
              </p>
            </div>
            <Form
              layout="vertical"
              style={{ marginTop: 25 }}
              onFinish={esqueceuSenha}
            >
              <Form.Item
                label="Email"
                name="emailForgot"
                hasFeedback
                help={msgEmailRec}
                validateStatus={msgEmailRec?'error':''}
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Digite seu e-mail!",
                  },
                ]}
              >
                <Input
                  placeholder="Digite seu e-mail"
                  size="middle"
                  type="email"
                  style={{ borderRadius: 8, padding: 10 }}
                />
              </Form.Item>
              <Form.Item style={{ marginTop: 10 }}>
                <Button
                  style={{
                    background: "#6900ea",
                    color: "#fff",
                    height: 45,
                    borderRadius: 8,
                  }}
                  htmlType="submit"
                  className="login-form-button"
                  size="middle"
                  loading={loading}
                  disabled={false}
                >
                  Entrar
                </Button>
              </Form.Item>
            </Form>
          </div>
        ) : (
          ""
        )}
        {resultSenha ? (
          <div className="result">
            <Result
              status="success"
              title="Verifique seu e-mail"
              subTitle={`Enviamos um email para ${emailRec}. Verifique sua caixa de entrada ou spam para recuperar sua senha. `}
            />
          </div>
        ) : (
          ""
        )}
      </Modal>

      {/* content */}
      <header className="header-login-page">
        <img src={logo} alt="Cronos-logo" className="logo-login-cronos" />
      </header>
      <Form name="normal_login" className="login-form" onFinish={Auth}>
        <Form.Item
          name="email"
          hasFeedback
          validateStatus={statusEmail}
          help={msgEmail}
          rules={[
            {
              required: true,
              type: "email",
              message: "Digite seu e-mail!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="E-mail"
            size="large"
            type="email"
            style={{ borderRadius: 8, padding: 10 }}
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          validateStatus={statusSenha}
          help={msgSenha}
          name="senha"
          rules={[
            {
              required: true,
              message: "Digite sua senha!",
            },
          ]}
        >
          <Input.Password
            size="large"
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Senha"
            style={{ borderRadius: 8, padding: 10 }}
          />
        </Form.Item>

        <Form.Item style={{ marginTop: 10 }}>
          <Button
            style={{
              background: "#6900ea",
              color: "#fff",
              height: 45,
              borderRadius: 8,
            }}
            htmlType="submit"
            className="login-form-button"
            size="large"
            loading={loading}
            disabled={false}
          >
            Entrar
          </Button>
        </Form.Item>
      </Form>
      <a onClick={forgotPass}>Esqueceu a senha?</a>
    </div>
  );
}

export default Login;
