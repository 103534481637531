import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';



function Notfound() {
  return  <Result
  status="404"
  title="Página não encontrada!"
  subTitle="Desculpa, a página acessada não existe."
  extra={<Link to={'/'}><Button type="primary">Voltar ao inicio</Button></Link>}
/>
}

export default Notfound;