import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';



function AccessDenied() {
  return  <Result
  status="403"
  title="Acesso negado!"
  subTitle="Desculpe, Você não possui permissão para esta página."
  extra={<Link to={'/'}><Button type="primary">Voltar ao inicio</Button></Link>}
/>
}

export default AccessDenied;