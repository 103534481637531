import {
  Button,
  Col,
  Input,
  Row,
  Table,
  Skeleton,
  Space,
  Drawer,
  Form,
  Select,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import yasoba from "../../Services/yasoba";
import {
  UserAddOutlined,
  SearchOutlined,
  EditOutlined,
} from "@ant-design/icons";

function Clientes() {
  document.title = "Clientes | OnlineTv";

  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([]);

  const [reloading, setReloading] = useState(false);

  const [visible, setVisible] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);

  const [client, setClient] = useState();
  const [planos, setPlanos] = useState([]);

  const [newPlano, setNewPlano] = useState(0);
  const [password, setPassword] = useState("");
  const [loadingEdit, setLoadingEdit] = useState(false);

  const [visibleCreate, setVisibleCreate] = useState(false);
  const [nome, setNome] = useState("");
  const [login, setLogin] = useState("");
  const [addPlano, setAddPlano] = useState("");
  const [senha, setSenha] = useState("");
  const [loadingCreate, setLoadingCreate] = useState(false);

  const columns = [
    {
      title: "MAX_ID",
      dataIndex: "ID",
      key: "ID",
    },
    {
      title: "Nome",
      dataIndex: "Nome",
      key: "Nome",
      render: (text) => {
        if (text !== null) {
          return <p>{text}</p>;
        } else {
          return <p>Sem nome</p>;
        }
      },
    },
    {
      title: "Login",
      dataIndex: "Login",
      key: "login",
    },
    {
      title: "Products",
      dataIndex: "Products",
      key: "Products",
      render: (text) => {
        if (text !== null) {
          return <p>{text}</p>;
        } else {
          return <p>Cliente sem plano</p>;
        }
      },
    },
    {
      title: "Ação",
      key: "action",
      render: (text) => {
        return (
          <Space size="middle" key={text.id}>
            <Button
              icon={<EditOutlined />}
              key={text.Login}
              size="middle"
              shape="round"
              type="primary"
              onClick={() => {
                editUser(text.ID);
              }}
            >
              Editar
            </Button>
          </Space>
        );
      },
    },
  ];


  async function createCliente() {
    setVisibleCreate(true);
    await yasoba
      .get("/api/planos")
      .then(async (response) => {
        setPlanos(response.data);
      })
      .catch(() => {
        setVisible(false);
      });
  }

  async function criarCliente() {
    setLoadingCreate(true)

    await yasoba.post('/api/criar-cliente',{
      "nome": nome,
      "login": login,
      "plano": addPlano,
      "senha": senha    
    }).then(()=>{
      setVisibleCreate(false)
      setLoadingCreate(false)
      setReloading(!reloading);
    }).catch((err)=>{
      notification.error({
        duration: 1,
        message: "Erro",
        description: err,
        placement: "topLeft",
      });
    })
  }

  async function editarUsuario() {
    setLoadingEdit(true);

    const dataApi = {
      id_cliente: client.id,
      new_plano: newPlano,
      password: password,
      id_plano: client.plano.id,
    };

    await yasoba.post("/api/editar-cliente", dataApi).then(() => {
      setReloading(!reloading);
      setLoadingEdit(false);
      setVisible(false);
      setLoadingUser(false);
      setClient("");
      setNewPlano(0);
      setPassword("");
    });
  }

  async function editUser(id_client) {
    setLoadingUser(true);
    await yasoba
      .get("/api/planos")
      .then(async (response) => {
        setPlanos(response.data);

        setVisible(true);
        setTimeout(() => {
          setLoadingUser(false);
        }, 1000);
      })
      .catch(() => {
        setVisible(false);
      });

    await yasoba.get(`/api/clientes/${id_client}`).then((response) => {
      setClient(response.data);
    });
  }

  useEffect(() => {
    setLoading(true);
    yasoba
      .get("/api/clientes")
      .then((response) => {
        setClients(response.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [reloading]);

  const { Option } = Select;

  return (
    <div className="cliente">
      <Drawer
        visible={visible}
        width={"35%"}
        title="Editar usuário"
        onClose={() => {
          if (!loadingEdit) {
            setVisible(false);
            setLoadingUser(false);
            setClient("");
            setNewPlano(0);
            setPassword("");
          } else {
            notification.info({
              duration: 1,
              message: "Aguarde",
              description: "Aguarde a alteração",
              placement: "topLeft",
            });
          }
        }}
      >
        <Skeleton active loading={loadingUser}>
          {client ? (
            <div className="edit-user" style={{ marginTop: 10 }}>
              <Form layout="vertical" autoComplete="chrome-off">
                <Row gutter={10}>
                  <Col span={5}>
                    <Form.Item label="ID">
                      <Input size="large" readOnly value={client.id} />
                    </Form.Item>
                  </Col>
                  <Col span={19}>
                    <Form.Item label="Login">
                      <Input
                        placeholder="Login"
                        size="large"
                        readOnly
                        value={client.login}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  label="Plano"
                  name={"plano"}
                  initialValue={client.plano.nome_plano}
                >
                  <Select
                    size="large"
                    onSelect={(value) => {
                      setNewPlano(value);
                    }}
                  >
                    {planos.map((l) => (
                      <Option key={l.id_plano} value={l.id_plano}>
                        {l.planos}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Col span={24}>
                  <Form.Item label="Senha" name={"password"}>
                    <Input.Password
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      autoComplete={"false"}
                      type={"password"}
                      placeholder="Nova senha"
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Form.Item>
                  <Button
                    loading={loadingEdit}
                    type="primary"
                    shape="round"
                    s
                    htmlType="submit"
                    size="large"
                    onClick={() => {
                      editarUsuario();
                    }}
                    disabled={
                      password !== "" ||
                      (newPlano !== client.plano.id && newPlano !== 0)
                        ? false
                        : true
                    }
                  >
                    Editar
                  </Button>
                </Form.Item>
              </Form>
            </div>
          ) : (
            <p>...Carregando</p>
          )}
        </Skeleton>
      </Drawer>

      {/* <h1></h1> */}

      <Drawer
        visible={visibleCreate}
        width={"35%"}
        title="Criar Usuário"
        onClose={() => {
          if (!loadingCreate) {
            setVisibleCreate(false);
            setLoadingCreate(false);
            setNome("");
            setLogin("");
            setAddPlano("");
            setSenha("");
          } else {
            notification.info({
              duration: 1,
              message: "Aguarde",
              description: "Aguarde a alteração",
              placement: "topLeft",
            });
          }
        }}
      >
        {visibleCreate?(
            <div className="create-cliente" style={{ marginTop: 10 }}>
            <Form layout="vertical" autoComplete="chrome-off">
              <Form.Item label="Nome Completo" required>
                <Input
                  required
                  placeholder="Nome Completo"
                  size="large"
                  onChange={(e) => {
                    setNome(e.target.value);
                  }}
                 
                />
              </Form.Item>
  
              <Form.Item label="Login" required>
                <Input
                  required
                  placeholder="Login"
                  size="large"
                  onChange={(e) => {
                    setLogin(e.target.value);
                  }}
                 
                />
              </Form.Item>
  
              <Form.Item label="Plano" name={"plano"} required>
                <Select
                  size="large"
                  placeholder="Selecione o plano"
                  onSelect={(value) => {
                    setAddPlano(value);
                  }}
                
                >
                  {planos.map((l) => (
                    <Option key={l.id_plano} value={l.id_plano}>
                      {l.planos}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
  
              <Form.Item
                label="Senha"
                name={"password"}
                required
                rules={[
                  {
                    min: 6,
                    message: "A senha deve conter pelo menos 6 caracteres",
                  },
                ]}
              >
                <Input.Password
                  required
                  onChange={(e) => {
                    setSenha(e.target.value);
                  }}
                  autoComplete={"false"}
                  type={"password"}
                  placeholder="Nova senha"
                  size="large"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  icon={<UserAddOutlined />}
                  loading={loadingCreate}
                  type="primary"
                  shape="round"
                  htmlType="submit"
                  size="large"
                  onClick={() => {
                    criarCliente();
                  }}
                  disabled={
                    senha !== "" && addPlano !== "" && nome !== "" && senha !== ""
                      ? false
                      : true
                  }
                >
                  Criar cliente
                </Button>
              </Form.Item>
            </Form>
          </div>
        ):''}
      </Drawer>

      <div className="clientes">
        <div className="header" style={{ margin: "0rem 0rem 1.3rem 0rem" }}>
          <Row justify="center" align="middle" gutter={[0, 99]}>
          <Col span={16}>
              <Row align="bottom" gutter={[10,10]}>
                <Col>
                  <h2 style={{lineHeight: '34px'}}>Clientes</h2>
                </Col>
                <Col>
                  <Button icon={<UserAddOutlined />} type="primary" onClick={()=>createCliente()}> Novo cliente </Button>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row gutter={10}>
                <Col span={19}>
                  <Input security="1" disabled />
                </Col>
                <Col span={5}>
                  <Button disabled icon={<SearchOutlined />} type="primary">
                    Pesquisar
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Table
          rowKey={"ID"}
          size="large"
          dataSource={clients}
          columns={columns}
          loading={loading}
          pagination={{
            hideOnSinglePage: true,
            pageSize: 8,
            showSizeChanger: false,
          }}
        />
      </div>
    </div>
  );
}

export default Clientes;
