import React from "react";
import { Routes, Route } from "react-router-dom";
import { CustomProvider } from "rsuite";
import Navbar from "../Components/Navbar/Navbar";
import Clientes from "../Pages/Clientes/Clientes";
import Exceções from "../Pages/Exceções/Exceções";
import Home from "../Pages/Home/Home";
import Login from "../Pages/Login/Login";
import Notfound from "../Pages/Erros/Notfound";
import AccessDenied from "../Pages/Erros/AccessDenied";

function Rotas() {
  return (
    <CustomProvider theme={"dark"}>
      <Routes>
        <Route path={"/login"} element={<Login />} />

        <Route path={"/"} element={<Navbar />}>
          <Route index element={<Home />} />
          <Route path={"/clientes"} children element={<Clientes />} />
          <Route path={"/excecoes"} element={<Exceções />} />
          <Route path={"/*"} element={<Notfound />} />
          <Route path={"/access-denied"} element={<AccessDenied />} />
        </Route>
      </Routes>
    </CustomProvider>
  );
}

export default Rotas;
