import styled from "styled-components";
export const Container = styled.div`
  h2 {
    color: aliceblue;
  }
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  margin-left: 1rem;
  gap: 0.5rem;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  :hover {
    cursor: pointer;
  }
`;
