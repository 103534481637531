
import plutao from "./api";

export const useAuth = () => {
  const id_user = parseInt(localStorage.getItem("cronos_id_user"));
  const token = localStorage.getItem("cronos_token-user");

  if (token === null || id_user === null) {
    return false;
  } else {
    return plutao
      .post("/api/token", { token: token,id_user: id_user })
      .then((res) => {
        return true;
      })
      .catch(() => {
        localStorage.clear();
        return false;
      });
  }
};


