import { Divider, Dropdown, Row, Col, Menu, Avatar, notification } from "antd";
import React, { useEffect, useState } from "react";
import { Link, Outlet, Navigate, useNavigate } from "react-router-dom";
import { Navbar, Nav } from "rsuite";

import { LogoutOutlined } from "@ant-design/icons";

import plutao from "../../Services/api";
import { useAuth } from "../../Services/Auth";

import logo from "../../Assets/OnlineTV.svg";

import { Container } from "./styles";
import yasoba from "../../Services/yasoba";

function Dashboard() {
  const [user, setUser] = useState({});
  const id_user = parseInt(localStorage.getItem("cronos_id_user"));

  useEffect(() => {
    plutao.defaults.headers.Authorization =
      localStorage.getItem("cronos_token-user");
    yasoba.defaults.headers.Authorization =
      localStorage.getItem("cronos_token-user");

      console.log(typeof(id_user))

   if(!isNaN(id_user)){
    plutao
    .get(`/user/${id_user}`)
    .then((response) => {
      setUser(response.data);
    })
    .catch((err) => {
      notification.error({
        message: "ERRO!",
        description: "Usuário não localizado",
      });
    });
   }
  }, []);

  const menu = (
    <Menu>
      <Menu.Divider />
      <Menu.Item
        key="3"
        danger
        style={{ background: "#dc1126", color: "#fff" }}
        icon={<LogoutOutlined />}
        onClick={logout}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  const auth = useAuth();
  const navigate = useNavigate();

  async function logout() {
    await plutao
      .post("/api/logout", { id_user: localStorage.getItem("cronos_id_user") })
      .then(() => {
        localStorage.clear();
        notification.error({
          message: "Desconectado",
        });
        navigate("/login");
      });
  }

  function Logout() {
    useEffect(() => {
      plutao
        .post("/api/logout", {
          id_user: localStorage.getItem("cronos_id_user"),
        })
        .then(() => {
          localStorage.clear();
          notification.error({
            message: "Desconectado",
            description: "Sua sessão foi expirada! Voltando para Login",
          });
        });
    }, []);

    return <Navigate to={"/login"} />;
  }

  if (auth) {
    yasoba.defaults.headers.Authorization =
      localStorage.getItem("cronos_token-user");
    return (
      <div className="page">
        <Navbar appearance="subtle" style={{ padding: "1rem" }}>
          <nav style={{ margin: "0rem 3rem" }}>
            <Navbar.Brand as={Link} to="/">
              <img src={logo} alt="logo cronos" style={{ maxWidth: "70%" }} />
            </Navbar.Brand>
            <Nav>
              <Nav.Item as={Link} to="/clientes">
                Clientes
              </Nav.Item>
              {user.nome_perfil === "ADMINISTRADOR" ? (
                <Nav.Item as={Link} to="/excecoes">
                  Exceções
                </Nav.Item>
              ) : (
                ""
              )}
            </Nav>
            <Nav pullRight>
              <Dropdown overlay={menu} trigger={["click"]}>
                <Container>
                  <Row justify="center" align="middle" gutter={10}>
                    <Col>
                      <Avatar
                        size={"large"}
                        src={`https://avatars.dicebear.com/api/adventurer/${
                          user.nome + new Date().getMinutes()
                        }.svg?b=%6900ea&size=900&scale=114&rotate=360&translateX=3`}
                      />
                    </Col>
                    <Col>
                      <h4 style={{ margin: 0 }}> {user.nome} </h4>
                    </Col>
                  </Row>
                </Container>
              </Dropdown>
            </Nav>
          </nav>
        </Navbar>
        <Divider />
        <div className="main" style={{ margin: "0rem 5.7rem" }}>
          <Outlet />
        </div>
      </div>
    );
  } else {
    return <Logout />;
  }
}

export default Dashboard;
