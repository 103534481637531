import React from "react";
import ReactDOM from "react-dom";
import Rotas from "./Services/Routes";
import { BrowserRouter } from "react-router-dom";
import "antd/dist/antd.dark.min.css";
import "rsuite/dist/rsuite.min.css";

ReactDOM.render(
  <BrowserRouter>
    <Rotas />
  </BrowserRouter>,
  document.getElementById("root")
);
