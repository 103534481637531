import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import plutao from "../../Services/api";

// import { Container } from './styles';

function Home() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    plutao
      .get(`/user/${localStorage.getItem("cronos_id_user")}`)
      .then((response) => {
        if (response.data.nome_perfil !== "ADMINISTRADOR") {
          navigate("/clientes");
        } else {
          setLoading(true);
        }
      });
  }, []);
  if (loading) {
    return (
      <div className="home">
        <h2>Home</h2>
      </div>
    );
  }else{
    return <h1></h1>
  }
}

export default Home;
